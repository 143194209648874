<template>
  <a-modal
    v-model="isShow"
    :footer="null"
    width="800px"
    @cancel="handleCancel"
  >
    <a-spin :spinning="visible && image.length === 0">
      <div class="sfm-flex sfm-justify-center sfm-items-center">
        <img style="width: 90%" :src="image">
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
export default {
  name: 'PreviewImage',
  props: {
    // 是否预览
    visible: {
      type: Boolean,
      default: false
    },

    // 预览图片地址
    image: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    // 预览关闭
    handleCancel() {
      // 通知父组件关闭
      this.$emit('close')
    }
  }
}
</script>
